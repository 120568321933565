<template>
  <div class="card-info">
    <div class="card-info__header">
      <figure>
        <img :src="info.image" loading="lazy" cache-control="max-age=3600" />
      </figure>
      <span>
        {{ info.title }}
      </span>
      <p>
        {{ info.description }}
      </p>
    </div>
    <div class="card-info__body">
      <div class="card-info__body__list">
        <div
          v-for="(item, key) in info.links"
          :key="key"
          class="card-info__body__list__item"
        >
          <div v-if="item.children.length && (permissions.find(x => x === item.permission) || loggedIn)" class="main">
            <div class="main__header">
              <figure>
                <img :src="blueArrowIconPath" cache-control="max-age=3600" />
              </figure>
              <span>{{ item.title }}</span>
            </div>
            <ul v-if="item.children" class="main__list">
              <li
                v-for="(child, keyChild) in item.children"
                :key="keyChild"
                class="main__list__item"
              >
                <router-link :to="child.to" v-if="permissions.find(x => x === child.permission) || loggedIn">
                  <figure>
                    <img
                      :src="yellowArrowIconPath"
                      cache-control="max-age=3600"
                    />
                  </figure>
                  {{ child.title }}
                </router-link>
              </li>
            </ul>
          </div>
          <div v-else-if="permissions.find(x => x === item.permission) || loggedIn" class="main-no-child">
            <div class="main-no-child__header">
              <router-link :to="item.to">
                <figure>
                  <img :src="blueArrowIconPath" cache-control="max-age=3600" />
                </figure>
                {{ item.title }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: null,
    },
    permissions: {
      type: Array,
      default: null,
    },
    loggedIn: {
      type: Boolean,
      default: null,
    }, 
  },
  data() {
    return {
      blueArrowIconPath: "/icons/arrow-forward-blue-icon.svg",
      yellowArrowIconPath: "/icons/arrow-forward-yellow-icon.svg",
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.card-info {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 1.5rem 1.5rem 0;
  color: $z-black-1;
  height: 100%;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    text-align: center;

    figure {
      width: 52px;
      height: 52px;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 50%;
      }
    }
    span {
      text-transform: uppercase;
      // font-size: 13px;
      font-size: 0.95em;
      font-weight: 700;
    }
    p {
      font-weight: 400;
      // font-size: 1.12em;
      font-size: 0.85em;
    }
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: center;
    &__list {
      &__item {
        .main {
          &__header {
            display: flex;
            align-items: center;
            // gap: $z-s-1;
            gap: 0.5rem;
            span {
              // font-size: 1.1em;
              font-size: 0.85em;
            }
          }
          &__list {
            padding: 0;
            li {
              list-style: none;
              padding-left: $z-s-2;
              margin: $z-s-1 0;

              a {
                text-decoration: none;
                display: flex;
                align-items: center;
                // gap: $z-s-1;
                // font-size: 1.1em;
                gap: 0.5rem;
                font-size: 0.85em;
                color: $z-black-1;
              }
            }
          }
        }
        .main-no-child {
          // border: 1px solid blue;
          width: 100%;
          &__header {
            a {
              text-decoration: none;
              display: flex;
              align-items: center;
              // gap: $z-s-1;
              // font-size: 1.1em;
              gap: 0.5rem;
              font-size: 0.85em;
              color: $z-black-1;
              margin-bottom: $z-s-1;
            }
          }
        }
      }
    }
  }
}
</style>
