<template>
  <div>
    <Home />
  </div>
</template>

<script>
import Home from "@/components/Pages/Home/index.vue";
export default {
  components: {
    Home,
  },
};
</script>

<style lang="scss" scoped></style>
