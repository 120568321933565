export const connectionCard = {
  image: "/icons/connection-icon.svg",
  title: "Conexão",
  description:
    "Importe seus contatos e crie listas personalizadas para cada campanha.",
  permission: "16b4e062-1ce9-4bf2-ae4c-96f35af7f4b1",
  links: [
    {
      title: "Integração Dados Primários",
      children: [
        {
          title: "Conexões criadas",
          to: "/primary-data",
          permission: "5509cbdb-4aaf-47f7-be0c-3f396f42d6f8",
        },
        {
          title: "Criar nova conexão",
          to: "/primary-data/newConnection",
          permission: "de655e16-1a02-4ab1-abb2-b5dbc5679550",
        },
      ],
      permission: "f7722969-730c-4f78-879e-1a9c4d9b8335",
    },
    {
      title: "Integração Dados Adicionais",
      children: [
        {
          title: "Conexões criadas",
          to: "/additional-data",
          permission: "28582fdb-dc9b-4041-a7aa-1eb50bd64254",
        },
        {
          title: "Criar nova conexão",
          to: "/additional-data/newConnection",
          permission: "38dc9cde-1b42-402b-a627-af72f52932a5",
        },
      ],
      permission: "b7037ecb-dcae-4972-a69a-8d3124af97db",
    },
    // {
    //   title: "Integração Dados de Conversão",
    //   children: [
    //     {
    //       title: "Conexões criadas",
    //       to: "/conversion-data",
    //     },
    //     {
    //       title: "Criar nova conexão",
    //       to: "/conversion-data/newConnection",
    //     },
    //   ],
    // },
  ],
};

export const audienceCard = {
  image: "/icons/audience-icon.svg",
  title: "Audiência",
  description:
    "Importe seus contatos e crie listas personalizadas para cada campanha.",
  permission: "b9acb1d6-5d1a-46c0-8dbc-5a0c4fd9e71c",
  links: [
    {
      title: "Audiências",
      children: [
        {
          title: "Audiências criadas",
          // to: "/audience/addd-audiences",
          to: "/audience",
          permission: "e34efc33-6d4e-4704-825c-c18a396c6bae",
        },
        // {
        //   title: "Audiências filtradas criadas",
        //   // to: "/audience/addd-audiences",
        //   to: "/audience/filtered",
        // },
        {
          title: "Criar audiência",
          to: "/audience/add",
          permission: "7e05f536-4b90-4a14-9cc7-007f266d3de2",
        },
      ],
      permission: "b9acb1d6-5d1a-46c0-8dbc-5a0c4fd9e71c",
    },
    {
      title: "Opt-out",
      permission: "edf2a707-7338-4360-ace0-4727386f2d41",
      children: [
        {
          title: "Listagem de opt-outs",
          to: "/optout/list",
          permission: "3eee5247-7cef-4608-939b-cee75a36e389",
        },
      ],
    },
    // {
    //   title: "Audiência filtrada",
    //   children: [
    //     {
    //       title: "Criar novo filtro",
    //       to: "/audience/filter",
    //     },
    //   ],
    // },
  ],
};

export const contentCard = {
  image: "/icons/content-icon.svg",
  title: "Conteúdo",
  description:
    "Personalize sua comunicação de acordo com o objetivo da sua campanha.",
  permission: "fde6ef81-25c6-42bf-9de7-22f720a5d916",
  links: [
    {
      title: "Templates",
      children: [
        {
          title: "Templates criados",
          to: "/templates",
          permission: "c479ea75-b50a-47fd-8dfb-46586d836691",
        },
        {
          title: "Novo template E-mail",
          to: "/templates/email/new",
          permission: "da15f3ed-5fc6-4b91-9fac-e74fbd5cbc4b",
        },
        {
          title: "Novo template SMS",
          to: "/templates/sms",
          permission: "860f6b0f-da79-4c16-84b1-37a43886bcd8",
        },
        {
          title: "Novo template de Push",
          to: "/templates/push",
          permission: "303bfee3-a7b7-4fbe-8710-305358270067",
        },
        {
          title: "Novo template WhatsApp (Beta)",
          to: "/templates/whatsapp",
          permission: "c93ce00e-dcae-47f6-be01-c70623594ff6",
        },
      ],
      permission: "47f9f9c3-3aa9-475e-9e77-4803a04c9e92",
    },
  ],
};

export const journeyCard = {
  image: "/icons/journey-icon.svg",
  title: "Jornada",
  description:
    "Ative sua campanha em apenas um clique e deixe a automação trabalhar por você.",
  permission: "ec4a0874-7855-4213-9bc9-8bd41dc3a785",
  links: [
    {
      title: "Nova jornada",
      to: "/journey",
      children: [],
      permission: "2d9b0f99-7194-45a2-b729-9e2730d72655",
    },
    {
      title: "Jornadas criadas",
      to: "/journey/created",
      children: [],
      permission: "7cb12d80-302a-44ef-bf67-08801cfc22eb",
    },
    {
      title: "Configurações de envio",
      to: "/credentials",
      permission: "be85974b-2e1d-4971-a881-8f3525c30a3d",
      children: [],
    },
  ],
};

export const reportCard = {
  image: "/icons/report-icon.svg",
  title: "Relatório",
  description:
    "Analise os resultados detalhados de cada campanha através dos dashboards.",
  permission: "ea88d8a1-b24b-4443-8e4f-7dd2f291b911",
  links: [
    {
      title: "Relatório geral",
      to: "/reports",
      children: [],
      permission: "5bd0c759-bb54-427c-a1e3-3c897b9bf117",
    },
    // {
    //   title: "Relatório de interatividade",
    //   to: "/interactivity-report",
    //   children: [],
    // },
  ],
};
